<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="sms_headers"
          :items="sms_list"
          :options.sync="options"
          :server-items-length="total_sms"
          :multi-sort="false"
          item-key="id"
          :search="search"
          :loading="loading"
          loading-text="Загрузка... Подождите"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-options': [15, 25, 50, 100, -1],
          }"
        >
          <template v-slot:top>
            <v-toolbar dense flat>
              <v-text-field
                v-model="search"
                hide-details
                label="Search"
                prepend-icon="mdi-magnify"
                single-line
              ></v-text-field>

              <v-btn icon @click="send_sms">
                <v-icon>mdi-send-circle</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <send-s-m-s-dialog />
  </v-container>
</template>

<script>
import sendSMSDialog from "./sendSMSDialog";

export default {
  name: "Sms",
  components: {
    sendSMSDialog,
  },
  data: () => {
    return {
      search: "",
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      sms_headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "sms_id",
        },
        {
          text: "Цена",
          align: "start",
          sortable: true,
          value: "cost",
        },
        {
          text: "Отправлено",
          align: "start",
          sortable: true,
          value: "sended_at",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Клиент",
          align: "start",
          sortable: true,
          value: "client.phone",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("list_sms", this.options);
  },
  watch: {
    options: {
      handler() {
        this.$store.dispatch("list_sms", this.options);
      },
      deep: true,
    },
  },
  computed: {
    sms_list() {
      return this.$store.getters.SMS;
    },
    total_sms() {
      return this.$store.getters.SMS_COUNT;
    },
    loading() {
      return this.$store.getters.SMS_LOADING;
    },
  },
  methods: {
    send_sms() {
      this.$store.dispatch("send_sms_dialog", true);
    },
  },
};
</script>

<style scoped></style>
