<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Отправить смс</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="sms" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Номер*"
                    v-model="phone"
                    placeholder="+7(###)###-##-##"
                    v-mask="phoneMask"
                    :rules="[(v) => !!v || 'Обязательно для заполнения']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Текст*"
                    v-model="text"
                    maxlength="70"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*обязательные поля</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="close"> Закрыть </v-btn>
          <v-btn color="blue darken-1" text @click="send"> Добавить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "sendSMSDialog",
  data: () => {
    return {
      valid: true,
      phone: "",
      text: "",
      phoneMask: "+7(###)###-##-##",
      textRules: [
        (v) => !!v || "Обязательно для заполнения",
        (v) =>
          (v && v.length <= 70) || "Текст не должен быть больше 70 символов",
      ],
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.SMS_DIALOG;
    },
  },
  methods: {
    validate() {
      this.$refs.sms.validate();
    },
    send() {
      console.log("send");
      this.validate();
      if (!this.valid) {
        return;
      }
      const sms = {
        phone: this.phone,
        text: this.text,
      };
      this.$store.dispatch("send_sms", sms).then(() => {
        this.close();
      });
    },
    close() {
      this.$store.dispatch("send_sms_dialog", false);
      this.$refs.sms.reset();
    },
  },
};
</script>

<style scoped></style>
